import {useEffect, useState} from "react";

export function useStateCallback<Type = any>(initValue: Type, callback: CallableFunction): [Type, (value: Type) => void] {
    const [value, setValue] = useState<Type>(initValue);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {

        if (!ready) {
            setReady(true);
            return;
        }

        if (callback) {
            callback();
        }
    }, [value]);

    return [value, setValue];
}